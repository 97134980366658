import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WebinarImg from '../../img/webinar.jpg'
function DrugDataBase() {
    const navigate=useNavigate()
    const [SearchQuery, setSearchQuery] = useState('')
  return (
    <div className="registerform-wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-4">
        <img src={WebinarImg} alt="" />
      </div>
      <div className="col-lg-8 pl-lg-5">
        <div className="drugsearch">
          <h2>Yogam</h2>
          <p>
            Lorem ipsum dolor sitamet consectetur adipiscing elitphasellus augue
            sem faucibus vitae felisvel accumsan eleifend sem.
          </p>
          <form onSubmit={()=>navigate(`/Drug?q=${SearchQuery}`)}>
          <div className="ask-text-input">
            <input type="text" className="form-control" placeholder="Search" onChange={(e)=>setSearchQuery(e.target.value)} value={SearchQuery}/>
            <button type='submit'>
              <i className="fas fa-search" />
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default DrugDataBase