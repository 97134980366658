import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { audioState } from '../../audioState';

function AudioContainer() {
    const [audioData, setAudioData] = useRecoilState(audioState);
    const [open, setOpen] = useState(audioData?.isMinimized ?? false);
    const audioRef = useRef(null);

    useEffect(() => {
        console.log('audiodata', audioData);
        setOpen(audioData?.isMinimized ?? false);
        console.log(audioData.currentTime, 'time');
    }, [audioData]);

    const handleExpand = () => {
        const currentTime = audioData.currentTime;
        console.log('current time in container', currentTime);

        setAudioData((prevState) => ({
            ...prevState,
            isPlaying: true, // Pause audio instead of resetting data
            isMinimized: false, // Reset minimized state
            currentTime: prevState.currentTime
        }));
    };
    const handleClose = () => {
        audioData?.handleCloseModal?.();
        setAudioData((prevState) => ({
            ...prevState,
            isPlaying: false, // Pause audio instead of resetting data
            isMinimized: false // Reset minimized state
        }));
        setOpen(false);
    };
    const isMinimized = audioData?.isMinimized;

    return (
        open && (
            <div
                style={{
                    position: 'fixed',
                    bottom: '60px',
                    right: '20px',
                    width: '150px', // Adjusted width
                    height: '50px', // Adjusted height
                    backgroundColor: '#f1f1f1',
                    borderRadius: '10px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    padding: '5px',
                    display: audioData?.isMinimized ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                {(audioData?.videoUrl || audioData?.audioSrc) && (
                    <iframe
                        src= "https://workdrive.zohoexternal.com/embed/0u1b341586d8654074702965863484d870d4e?toolbar=false&appearance=light&themecolor=green" // Use videoUrl first if available
                        ref={audioRef}
                        autoPlay={audioData?.isPlaying}
                        controls
                        controlsList="nodownload"
                        style={{ width: '100%', height: '30px' }}
                        onLoadedMetadata={(e) => {
                            e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                        }}
                        onEnded={() =>
                            setAudioData((prev) => ({
                                ...prev,
                                isPlaying: false
                            }))
                        }
                    />
                )}

                <button
                    onClick={isMinimized ? handleExpand : handleClose}
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <i className="fas fa-expand-arrows-alt"></i>
                </button>
            </div>
        )
    );
}

export default AudioContainer;
