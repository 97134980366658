import React from "react";
import { useNavigate } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import termsSvg from "../../img/terms_and_conditions.svg";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const Terms = () => {
  const redirect = useNavigate();
  const handleBackButtonPress = () => {
    redirect("/user-home");
  };
  useHardwareBackButton(0, handleBackButtonPress);
  return (
    <div className="flex h-screen flex-col">
      <div className="relative w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="container relative mx-auto flex  h-screen flex-1 overflow-hidden px-4 py-10 md:px-20 md:py-36">
        <div
          className={
            "sticky top-[60px] hidden w-full space-y-1 md:block md:max-w-[250px] lg:max-w-[300px]"
          }
        >
          <h1 className="mb-4 text-xl font-semibold lg:text-2xl">
            Terms and Conditions
          </h1>
          <a className="block list-none" href="#Terms_and_Conditions">
            Terms and Conditions
          </a>
        </div>
        <div className="w-full overflow-y-auto overscroll-none scrollbar-hide">
          <img
            src={termsSvg}
            alt={"terms and conditions"}
            className="mx-auto h-auto w-full max-w-[600px]"
          />
          <div className="mb-8">
            <h1
              id="Terms_and_Conditions"
              className="mb-3 text-4xl font-semibold tracking-tight"
            >
              Terms and Conditions
            </h1>
            <p className="mb-10 leading-7 tracking-wide">
              Welcome to Prithvi. Please read these Terms and Conditions
              ("Terms", "Terms and Conditions") carefully before using the
              Prithvi app (the "Service") operated by ATHULYAM ("us", "we", or
              "our").
            </p>
            <ul className="ml-1 space-y-2">
              <li className={"list-decimal"}>
                <b>Acceptance of Terms</b> By accessing and using the Service,
                you accept and agree to be bound by the terms and provisions of
                this agreement. If you do not agree to abide by these terms,
                please do not use this Service.
              </li>
              <li className={"list-decimal"}>
                <b>Changes to Terms</b> We reserve the right, at our sole
                discretion, to modify or replace these Terms at any time. If a
                revision is material, we will provide at least 30 days' notice
                prior to any new terms taking effect. What constitutes a
                material change will be determined at our sole discretion.
              </li>
              <li className={"list-decimal"}>
                <b>Use of the Service</b> You agree to use the Service only for
                lawful purposes and in a way that does not infringe the rights
                of, restrict, or inhibit anyone else's use and enjoyment of the
                Service.
              </li>
              <li className={"list-decimal"}>
                <b>Privacy Policy</b> Your use of the Service is also subject to
                our Privacy Policy. Please review our Privacy Policy, which
                governs the Service and informs users of our data collection
                practices.
              </li>
              <li className={"list-decimal"}>
                <b>Accounts</b> When you create an account with us, you must
                provide us with information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of
                the Terms, which may result in immediate termination of your
                account on our Service.
              </li>
              <li className={"list-decimal"}>
                <b>Subscriptions</b> Some parts of the Service are billed on a
                subscription basis ("Subscription(s)"). You will be billed in
                advance on a recurring and periodic basis ("Billing Cycle").
                Billing cycles are set either on a monthly or annual basis,
                depending on the type of subscription plan you select when
                purchasing a Subscription.
              </li>
              <li className={"list-decimal"}>
                <b>Free and Paid Content</b> Prithvi offers both free and paid
                educational content. Free content is accessible to all users,
                while access to paid content requires a subscription. The
                availability of content is subject to change, and we reserve the
                right to modify, remove, or add content at any time.
              </li>
              <li className={"list-decimal"}>
                <b>Intellectual Property</b> The Service and its original
                content, features, and functionality are and will remain the
                exclusive property of [Your Company Name] and its licensors. The
                Service is protected by copyright, trademark, and other laws of
                India and foreign countries.
              </li>
              <li className={"list-decimal"}>
                <b>Termination</b> We may terminate or suspend your account and
                bar access to the Service immediately, without prior notice or
                liability, under our sole discretion, for any reason whatsoever
                and without limitation, including but not limited to a breach of
                the Terms.
              </li>
              <li className={"list-decimal"}>
                <b>Limitation of Liability</b> In no event shall [Your Company
                Name], nor its directors, employees, partners, agents,
                suppliers, or affiliates, be liable for any indirect,
                incidental, special, consequential, or punitive damages,
                including without limitation, loss of profits, data, use,
                goodwill, or other intangible losses, resulting from (i) your
                use or inability to use the Service; (ii) any unauthorized
                access to or use of our servers and/or any personal information
                stored therein; (iii) any interruption or cessation of
                transmission to or from the Service; (iv) any bugs, viruses,
                trojan horses, or the like that may be transmitted to or through
                our Service by any third party; and/or (v) any errors or
                omissions in any content or for any loss or damage incurred as a
                result of the use of any content posted, emailed, transmitted,
                or otherwise made available through the Service, whether based
                on warranty, contract, tort (including negligence), or any other
                legal theory, whether or not we have been informed of the
                possibility of such damage.
              </li>
              <li className={"list-decimal"}>
                <b>Governing Law</b> These Terms shall be governed and construed
                in accordance with the laws of India, without regard to its
                conflict of law provisions.
              </li>
              <li className={"list-decimal"}>
                <b>Contact Us</b> If you have any questions about these Terms,
                please contact us at psathulyam@gmail.com.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CombinedFooter />
    </div>
  );
};

export default Terms;
