import React from "react";
import { ArticleImgUrl } from "../../Constants/Config";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// Import required modules
import { Pagination, Autoplay } from "swiper/modules";

function BannerSlide({ BannerData }) {
  return (
    <>
      {BannerData?.length > 0 && (
        <div className="flex justify-center items-center py-8 px-4 sm:px-6 lg:px-8 bg-gray-50">
          <Swiper
            slidesPerView={3}  // Show 3 slides at a time
            spaceBetween={30}   // Adjust space between slides
            centeredSlides={false}  // Disable centered slides
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,  // Auto-slide every 3 seconds
              disableOnInteraction: false,  // Keep autoplay after user interaction
            }}
            modules={[Pagination, Autoplay]}  // Include Autoplay module
            className="w-full max-w-7xl" // Adjust max width if needed
          >
            {BannerData.map((data, index) => (
              <SwiperSlide key={index} className="relative flex justify-center items-center">
                <div className="w-[89o5px] h-[446px] overflow-hidden p-4"> {/* Set fixed width and height */}
                  <img
                    className="w-full h-full object-cover rounded-lg shadow-lg"
                    src={ArticleImgUrl + data.ImageUrl}
                    alt={data.Title || "Slide image"}
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transparent to-transparent p-4 text-white">
                    <h3 className="text-lg font-semibold">{data.Title}</h3>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
}

export default BannerSlide;
