import React, { useState } from "react";
import Slider from "react-slick";

import { getSession } from "../../lib/session";
import { VideoThumbUrl } from "../../Constants/Config";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AudioPlayModal from "../Modals/AudioPlayModal";
import { useEffect } from "react";
import AudioPlayer from "../Modals/AudioPlayModal";

function AudioSlide(props) {
  const session = getSession();
  const navigate = useNavigate();

  const audioData = props.AudioData || [];

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const toggleAudioState = (Data) => {
    console.log("data = > ", Data);
    const isPremiumUser = session.isPremiumUser;
    const canAccessAudio = isPremiumUser || Data.IsPremium !== 1;
    if (!canAccessAudio) {
      toast.error("Please purchase a package");
      return;
    }
    // If access is granted, set the modal data and open the modal
    setModalData(Data);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const settings = {
    centerMode: false,
    centerPadding: "5px",
    slidesToShow: 4,
    arrows: false,
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <AudioPlayModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        AudioData={modalData}
      />

      {audioData.length > 0 && (
        <div className="trendingvideos">
          <div className="container">
            <div className="row" style={{ clear: "both", display: "flex" }}>
              <div
                className="col-lg-10 col-md-8 col-sm-12"
                onClick={() => navigate(`/Group/${props.GroupData.Id}/Type/8`)}
              >
                <h2 className="Group-Heading">{props.GroupData.Heading}</h2>
              </div>
              {audioData.length > 4 && (
                <div className="col-lg-2 col-md-4">
                  <button
                    className="viewall-btn"
                    onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/2`)
                    }
                  >
                    View All
                  </button>
                </div>
              )}
            </div>
            <Slider {...settings}>
              {audioData.map((data, index) => (
                <div className="video-item" key={index}>
                  <button
                    className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                    style={{ position: "relative" }}
                    onClick={() => toggleAudioState(data)}
                  >
                    <span className="Videospan premium-icon-span">
                      <img
                        src={VideoThumbUrl + data.ThumbImg}
                        alt="uploaded video"
                        style={{ width: "100%" }}
                      />
                    </span>
                    <div className="videocontent">
                      <font className="pulse-btn">
                        <i className={"fas fa-play"} />
                      </font>
                      <h6>{data.Heading}</h6>
                    </div>
                  </button>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}

export default AudioSlide;
