import React, { useState, useCallback } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { twMerge } from "tailwind-merge";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDrug } from "../../Redux/UserHome/action";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { MdOutlineClose, MdWorkspacePremium } from "react-icons/md";
import { getSession } from "../../lib/session";
import { htmlToPlainText, stripHtmlTags } from "../../lib/helper";
import { DrugImgUrl } from "../../Constants/Config";
import alphabets from "../../Constants/alphabets.json";
import CombinedFooter from "../Footer/CombinedFooter";
import "../../Pages/Css/DrugList.css";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
const DrugList = () => {
  const DrugData = useSelector((state) => state.UserHomeReducer.GetAllDrug);
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(searchParams.get("q") ?? "");
  const dispatch = useDispatch();
  const session = getSession();
  let isPremiumUser = session.isPremiumUser;

  const [alphabet, setAlphabet] = useState("a");
  const redirect = useNavigate();
  const handleBackButtonPress=()=>{
    redirect('/user-home')
  }
  useHardwareBackButton(0,handleBackButtonPress) 
  useEffect(() => {
    dispatch(GetAllDrug());
  }, []);

  let drugs = useCallback(() => {
    const searchValue = state ? state.toLowerCase() : alphabet.toLowerCase();

    let res = DrugData?.filter((drug) => {
      if (state) {
        return drug.Heading.toLowerCase().includes(searchValue);
      } else {
        return (drug.Heading).trim().toLowerCase().startsWith(searchValue);
      }
    });

    return res;
  }, [alphabet, DrugData, state]);

  /**
   * This function is triggered when the user changes the alphabet selection.
   * @param {Event} e - The event object.
   */
  const handleAlphabetChange = (e) => {
    let name = e.target.getAttribute("name");
    if (state) {
      setState("");
    }

    setAlphabet(name);
  };

  useEffect(() => {
    setSearchParams({ q: state });
    if (state.charAt(0).toLowerCase() !== alphabet.toLowerCase()) {
      setAlphabet(state ? state.charAt(0).toLowerCase() : "a");
    }
  }, [state]);

  const handleInputChange = (e) => {
    setState(e.target.value);
  };

  return (
    <div className="flex h-screen flex-col overflow-y-hidden">
      <div className="w-full pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-[42px]  w-full justify-start bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        </div>
      </div>
      <div className="text-center">
        <h2 className="color: [#003a66] min-h-[50px] w-full bg-[#e5ebef] text-center text-[18px] capitalize leading-[50px]">
         Yogam
        </h2>
      </div>
      {/* <div className="w-full text-center flex items-center justify-center mt-3">
        <div className="flex h-12 w-12 uppercase cursor-pointer items-center justify-center rounded-md bg-forumBg font-semibold text-black transition scale-105">
          {alphabet}
        </div>
      </div> */}
      <div className="flex h-full flex-auto overflow-hidden pb-16 pt-4 sm:!py-4 sm:pb-0 sm:pt-0">
        <div className="mx-auto flex w-full max-w-7xl">
          <div className="flex-1 px-2 overflow-y-auto pb-4 scrollbar-hide relative">
            <div className="relative pb-2 mb-2 pt-1 bg-white sticky top-0 z-[999]">
              <input
                type="text"
                className="titext"
                value={state}
                onChange={handleInputChange}
                placeholder={`What's on your mind?`}
              />

              <div className="absolute inset-y-0 right-0 mr-3 flex items-center justify-center space-x-2">
                <button
                  type={"button"}
                  className={`${state ? "translate-x-0 scale-100" : "translate-x-full scale-0"} transform transition-transform`}
                  onClick={() => setState("")}
                >
                  <MdOutlineClose
                    className={`h-5 w-5 transition-all ${state ? "opacity-100" : "opacity-0"}`}
                  />
                </button>
              </div>
            </div>
            <ul
              role="list"
              className="grid grid-cols-1  m-2 gap-3 divide-y overflow-visible divide-gray-100 md:grid-cols-2 lg:grid-cols-3"
            >
              {drugs()?.map((drug, index) => {
                let contentAvailable = isPremiumUser || !drug.IsPremium;
                return (
                  <li
                    key={index}
                    onClick={() =>
                      contentAvailable
                        ? redirect(`/drug/${drug.Id}`)
                        : toast.error(
                            "To See this content you need to buy a package.",
                          )
                    }
                    className="group relative w-full cursor-pointer rounded-md bg-gray-100 p-2.5 transition-all hover:bg-gray-200"
                  >
                    <div className="flex max-h-[80px] min-w-0  gap-x-4 overflow-hidden">
                      <div className="min-w-0 flex-auto">
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-start space-x-3">
                            <div className="aspect-square relative h-16 w-16 rounded-md bg-transparent">
                              <img
                                src={`${DrugImgUrl}${drug.ImageUrl}`}
                                alt={drug.Heading}
                                className="h-16 w-16 rounded-lg object-contain"
                              />
                            </div>
                            <div className="flex-1">
                              <p className="text-md line-clamp-2 font-semibold leading-6 text-gray-900">
                                {drug.Heading}
                              </p>

                              <p className="line-clamp-3 bg-none text-justify !text-sm font-normal leading-6 text-gray-900">
                                {htmlToPlainText(drug.Description)}
                              </p>
                            </div>
                          </div>
                          <div className="h-full w-5">
                            <IoMdArrowDropright className="h-6 w-6 fill-zinc-700 transition-all group-hover:scale-125" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {drug.IsPremium ? (
                      <MdWorkspacePremium className="absolute right-0 top-0 z-50 h-7 w-7 -translate-y-1/4 translate-x-1/2 fill-amber-500 transition-all group-hover:scale-125" />
                    ) : null}
                  </li>
                );
              })}
            </ul>
            {drugs() && drugs()?.length <= 0 ? (
              <div className="flex h-full w-full items-start justify-center">
                <div className=" text-xl font-semibold">
                  {state ? `No data found for "${state}".` : `No data found!`}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="mb-0 w-fit overflow-y-auto overscroll-none px-2 scrollbar-hide md:!pe-4">
          <div className="flex flex-col items-center justify-start space-y-1 overflow-visible">
            {alphabets.map((el, _) => {
              return (
                <div
                  key={el.key}
                  name={el.value}
                  className={twMerge(
                    `flex h-12 w-12 scale-90 cursor-pointer items-center justify-center rounded-md bg-forumBg font-semibold text-black transition hover:scale-105`,
                    `${alphabet === el.value ? "bg-brown text-white opacity-100" : "opacity-80 hover:opacity-100"}`,
                  )}
                  onClick={handleAlphabetChange}
                >
                  {el.alphabet}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <CombinedFooter />
    </div>
  );
};

export default DrugList;
