import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { twMerge as tw } from 'tailwind-merge';
import { registerSchema } from '../../lib/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { MdOutlineAccessTime, MdOutlineErrorOutline } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    GetCountry,
    GetState,
    RegisterUser
} from '../../Redux/Authentication/action';
import toast from 'react-hot-toast';
import { ImSpinner2 } from 'react-icons/im';
import states from '../../Constants/states.json';
import countries from '../../Constants/countries.json';
import { getValue } from '@testing-library/user-event/dist/utils';
import { ApiUrl } from '../../Constants/Config';

const RegisterForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);

    const [instType, setInstType] = useState();

    useEffect(() => {
        dispatch(GetState());
        dispatch(GetCountry());
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        control,
        reset,
        watch
    } = useForm({
        // resolver: zodResolver(registerSchema),
        defaultValues: {
            Name: '',
            Mobile: '',
            Email: '',
            Institution: '',
            RegistrationNumber: '',
            DrOrStudent: '',
            Country: 'India',
            State: '',
            Designation: '',
            Institution: '',
            Department: '',
            regNo: '',
            DOB: '',
            Id: '',
            Description: ''
        }
        //         Country: "India"
        // DrOrStudent: ""
        // Email: ""
        // Id: 0
        // Institution:""
        // Mobile: ""
        // Name: ""
        // RegistrationNumber: ""
        // State: ""
        // Status: 0
        // DOB:""
        // Designation:""
        // Description:""
        // Gender:""
    });
    const formValues = watch(); // This will give you the current form values

    // Log the form values whenever they change
    useEffect(() => {
        console.log('formValues', formValues);
    }, [formValues]);
    const countryChange = useWatch({
        control,
        name: 'Country'
    });

    const States = useCallback(() => {
        console.log('countryChange => ', countryChange);
        console.log(
            'available states => ',
            states.filter(
                (el) =>
                    el.country_id ==
                    countries.find((el) => el.name === countryChange).id
            )
        );

        return (
            <div className="flex w-full flex-1 flex-col space-y-2">
                <label htmlFor="state">State</label>
                <div>
                    <div className="relative h-full">
                        <select
                            id="state"
                            className={tw(
                                `titext`,
                                errors.State ? 'titext-error' : ''
                            )}
                            {...register('State')}
                        >
                            <option value="" disabled selected>
                                Select a state
                            </option>
                            {states && states.length > 0 ? (
                                states
                                    .filter(
                                        (el) =>
                                            el.country_id ==
                                            countries.find(
                                                (el) =>
                                                    el.name === countryChange
                                            )?.id
                                    )
                                    .map((el, _) => {
                                        return (
                                            <option
                                                className={`p-3`}
                                                key={el.id}
                                                value={el.name}
                                            >
                                                {el.name}
                                            </option>
                                        );
                                    })
                            ) : (
                                <option value={'karnataka'}>
                                    default state
                                </option>
                            )}
                        </select>
                    </div>
                    {errors.State && (
                        <span className="text-sm font-medium text-red-500">
                            {errors.State.message}
                        </span>
                    )}
                </div>
            </div>
        );
    }, [countryChange]);

    const onSubmit = async (data) => {
        console.log('data sign up', { ...data, Id: 0, Status: 0 });
        let parsedData = { ...data, Id: 0, Status: 0 };
        // alert("clicked")
        try {
            setSpinner(true);
            // alert("clicked try")
            const response = await fetch(`${ApiUrl}/Users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(parsedData)
            })
                .then((res) => {
                    // alert("response then")
                    return res.json();
                })
                .catch((err) => {
                    // alert("response catch")
                });
            // alert(' ok')

            console.log('response', response);
            if (response && response?.length > 0 && response[0].Error == 111) {
                toast.error('User Already Exists! Try logging in!!!');
                setTimeout(() => {
                    navigate(`/?email=${getValues('Email')}`);
                    navigate(`/`);
                }, 3000);
            } else if (response && response.Id) {
                console.log('response => ', response);
                // alert('inside');

                // toast.success(
                //     `Congratulations!  🌟 
                //      Your has  registration is complete. Your password is in your inbox. Welcome to Prithvi’s Ayurvedic journey!`,
                //     {
                //         icon: (
                //             <MdOutlineAccessTime className="h-8 w-8 text-yellow-300" />
                //         ),
                //         autoClose: false, // keeps the toast open indefinitely
                //         closeOnClick: false, // prevents closing on click outside of the close button
                //         closeButton: <button>Close</button> // custom close button
                //     }
                // );
                handlebutton();
                navigate(`/`);
                reset();
            }
        } catch (error) {
            toast.error('Something went wrong!!!');
        } finally {
            setSpinner(false);
        }
    };
    // Designation
    console.log('inst', errors);
    const handlebutton = () => {
        toast.custom(
            (t) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#fff',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        maxWidth: '400px',
                        position: 'relative'
                    }}
                >
                    <div style={{ fontSize: '16px', marginBottom: '8px' }}>
                        Congratulations! 🌟
                    </div>
                    <div style={{ fontSize: '14px', marginBottom: '12px' }}>
                        Your registration is complete. Your password is in your
                        inbox. Welcome to Prithvi’s Ayurvedic journey!
                    </div>
                    <button
                        style={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                            color: '#999'
                        }}
                        onClick={() => toast.dismiss(t.id)} // Closes the toast when the button is clicked
                    >
                        ✕
                    </button>
                </div>
            ),
            {
                duration: Infinity // Prevents auto-closing
            }
        );
    };

    return (
        <div className="mx-auto flex h-full w-full max-w-7xl flex-1 flex-col items-center justify-start px-4 py-10 md:justify-center">
            <h1 className="text-3xl font-bold">Create A User</h1>
            <form
                className="mx-auto mt-5 w-full space-y-2 md:space-y-5"
                onSubmit={handleSubmit(onSubmit)}
            >
                {/* > */}
                <>
                    <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="name">Name</label>
                            <div>
                                <div className="relative h-full">
                                    <input
                                        type="text"
                                        id="name"
                                        className={tw(
                                            `titext`,
                                            errors.Name ? 'titext-error' : ''
                                        )}
                                        placeholder="Name"
                                        {...register('Name')}
                                        required
                                    />
                                    {errors.Name && (
                                        <MdOutlineErrorOutline
                                            className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                        />
                                    )}
                                </div>
                                {errors.Name && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Name.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="phone">Phone</label>
                            <div>
                                <div className="relative h-full">
                                    <input
                                        type="text"
                                        id="phone"
                                        className={tw(
                                            `titext`,
                                            errors.Mobile ? 'titext-error' : ''
                                        )}
                                        placeholder="Phone"
                                        {...register('Mobile')}
                                        required
                                    />
                                    {errors.Mobile && (
                                        <MdOutlineErrorOutline
                                            className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                        />
                                    )}
                                </div>
                                {errors.Mobile && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Mobile.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="email">Date of birth</label>
                            <div>
                                <div className="relative h-full">
                                    <input
                                        type="date"
                                        id="DOB"
                                        className={tw(
                                            `titext`,
                                            errors.DOB ? 'titext-error' : ''
                                        )}
                                        {...register('DOB')}
                                        required
                                    />
                                    {errors.DOB && (
                                        <MdOutlineErrorOutline
                                            className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                        />
                                    )}
                                </div>
                                {errors.DOB && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.DOB.message}
                                        required
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="gender">Gender</label>
                            <div className="relative h-full">
                                <select
                                    id="gender"
                                    {...register('gender')}
                                    className={tw(
                                        `titext w-full rounded border p-2`,
                                        errors.DrOrStudent ? 'titext-error' : ''
                                    )}
                                    required
                                >
                                    <option value="" disabled selected>
                                        Select Gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="female">Others</option>
                                </select>
                            </div>
                            {errors.gender && (
                                <span className="text-sm font-medium text-red-500">
                                    {errors.gender.message}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="email">Email</label>
                            <div>
                                <div className="relative h-full">
                                    <input
                                        type="text"
                                        id="email"
                                        className={tw(
                                            `titext`,
                                            errors.Email ? 'titext-error' : ''
                                        )}
                                        placeholder="example@domain.com"
                                        {...register('Email')}
                                        required
                                    />
                                    {errors.Email && (
                                        <MdOutlineErrorOutline
                                            className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                        />
                                    )}
                                </div>
                                {errors.Email && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Email.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        {/* <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="regNo">Registration Number</label>
                            <div>
                                <div className="relative h-full">
                                    <input
                                        type="text"
                                        id="regNo"
                                        className={tw(
                                            `titext`,
                                            errors.RegistrationNumber
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="12345"
                                        {...register('RegistrationNumber')}
                                        // required
                                    />
                                    {errors.RegistrationNumber && (
                                        <MdOutlineErrorOutline
                                            className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                        />
                                    )}
                                </div>
                                {errors.RegistrationNumber && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.RegistrationNumber.message}
                                    </span>
                                )}
                            </div>
                        </div> */}
                    </div>

                    <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                        {/* <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="instituition">Institution</label>
                            <div>
                                <div className="relative h-full">
                                    <input
                                        type="text"
                                        id="instituition"
                                        className={tw(
                                            `titext`,
                                            errors.Institution
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Institution"
                                        {...register('Institution')}
                                    />
                                    {errors.Institution && (
                                        <MdOutlineErrorOutline
                                            className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                        />
                                    )}
                                </div>
                                {errors.Institution && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Institution.message}
                                    </span>
                                )}
                            </div>
                        </div> */}
                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="userType">User Type</label>
                            <div>
                                <div className="relative h-full">
                                    <select
                                        id="userType"
                                        {...register('DrOrStudent')}
                                        className={tw(
                                            `titext`,
                                            errors.DrOrStudent
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        onChange={(e) =>
                                            setInstType(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="" disabled selected>
                                            Select an option
                                        </option>
                                        <option value={'Student'}>
                                            Student
                                        </option>
                                        <option value={'vaidya'}>Vaidya</option>
                                        <option value={'pgscholar'}>
                                            PG Scholar
                                        </option>
                                        <option value={'PHD'}>PHD</option>
                                        <option value={'Enthusiast'}>
                                            Enthusiast
                                        </option>
                                    </select>
                                </div>
                                {errors.DrOrStudent && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.DrOrStudent.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                        <div className="flex w-full flex-1 flex-col space-y-2">
                            <label htmlFor="country">Country</label>
                            <div>
                                <div className="relative h-full">
                                    <select
                                        id="country"
                                        className={tw(
                                            `titext`,
                                            errors.Country ? 'titext-error' : ''
                                        )}
                                        {...register('Country')}
                                    >
                                        <option value="" disabled selected>
                                            Select a country
                                        </option>
                                        {countries && countries.length > 0 ? (
                                            countries.map((el, _) => {
                                                return (
                                                    <option
                                                        className={`p-3`}
                                                        key={el.id}
                                                        value={el.name}
                                                    >
                                                        {el.name}
                                                    </option>
                                                );
                                            })
                                        ) : (
                                            <option value={'India'}>
                                                India
                                            </option>
                                        )}
                                    </select>
                                </div>
                                {errors.Country && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Country.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <States />
                    </div>
                </>

                {instType == 'Student' && (
                    <>
                        <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                            <div className="flex w-full flex-1 flex-col space-y-2">
                                <label htmlFor="collegeName">
                                    College Name
                                </label>
                                <input
                                    type="text"
                                    id="collegeName"
                                    className={tw(
                                        `titext`,
                                        errors.CollegeName ? 'titext-error' : ''
                                    )}
                                    placeholder="College Name"
                                    {...register('CollegeName')}
                                />
                                {errors.CollegeName && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.CollegeName.message}
                                    </span>
                                )}
                            </div>

                            <div className="flex w-full flex-1 flex-col space-y-2">
                                <label htmlFor="Designation">Profession</label>
                                <select
                                    id="Designation"
                                    className={tw(
                                        `titext`,
                                        errors.Designation ? 'titext-error' : ''
                                    )}
                                    {...register('Designation')}
                                >
                                    <option value="">
                                        Select your Profession
                                    </option>
                                    <option value="1st">1st</option>
                                    <option value="2nd">2nd</option>
                                    <option value="3rd">3rd</option>
                                    <option value="internee">Internee</option>
                                </select>
                                {errors.Designation && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Designation.message}
                                    </span>
                                )}
                            </div>

                            <div className="flex w-full flex-1 flex-col space-y-2">
                                <label htmlFor="collegeID">
                                    Any College ID (optional)
                                </label>
                                <input
                                    type="text"
                                    id="collegeID"
                                    className={tw(
                                        `titext`,
                                        errors.AnyCollegeID
                                            ? 'titext-error'
                                            : ''
                                    )}
                                    placeholder="College ID"
                                    {...register('AnyCollegeID')}
                                />
                                {errors.AnyCollegeID && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.AnyCollegeID.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}

                {instType == 'vaidya' && (
                    <>
                        <>
                            <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="institutionName">
                                        Institution Name
                                    </label>
                                    <input
                                        type="text"
                                        id="institutionName"
                                        className={tw(
                                            `titext`,
                                            errors.Institution
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Institution Name"
                                        {...register('Institution')}
                                    />
                                    {errors.Institution && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.Institution.message}
                                        </span>
                                    )}
                                </div>

                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="department">
                                        Department
                                    </label>
                                    <input
                                        type="text"
                                        id="department"
                                        className={tw(
                                            `titext`,
                                            errors.Department
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Department"
                                        {...register('Department')}
                                    />
                                    {errors.Department && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.Department.message}
                                        </span>
                                    )}
                                </div>

                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="regNo">
                                        Registration Number (Mandatory)
                                    </label>
                                    <input
                                        type="text"
                                        id="regNo"
                                        className={tw(
                                            `titext`,
                                            errors.regNo ? 'titext-error' : ''
                                        )}
                                        placeholder="Registration Number"
                                        {...register('regNo')}
                                    />
                                    {errors.regNo && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.regNo.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </>
                    </>
                )}

                {instType == 'pgscholar' && (
                    <>
                        <>
                            <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="institutionName">
                                        Institution Name
                                    </label>
                                    <input
                                        type="text"
                                        id="institutionName"
                                        className={tw(
                                            `titext`,
                                            errors.Institution
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Institution Name"
                                        {...register('Institution')}
                                    />
                                    {errors.Institution && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.Institution.message}
                                        </span>
                                    )}
                                </div>

                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="department">
                                        Department
                                    </label>
                                    <input
                                        type="text"
                                        id="department"
                                        className={tw(
                                            `titext`,
                                            errors.Department
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Department"
                                        {...register('Department')}
                                    />
                                    {errors.Department && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.Department.message}
                                        </span>
                                    )}
                                </div>

                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="regNo">
                                        Registration Number (Mandatory)
                                    </label>
                                    <input
                                        type="text"
                                        id="regNo"
                                        className={tw(
                                            `titext`,
                                            errors.regNo ? 'titext-error' : ''
                                        )}
                                        placeholder="Registration Number"
                                        {...register('regNo')}
                                    />
                                    {errors.regNo && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.regNo.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </>
                    </>
                )}

                {instType == 'PHD' && (
                    <>
                        <>
                            <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="institutionName">
                                        Institution Name
                                    </label>
                                    <input
                                        type="text"
                                        id="institutionName"
                                        className={tw(
                                            `titext`,
                                            errors.Institution
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Institution Name"
                                        {...register('Institution')}
                                    />
                                    {errors.Institution && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.Institution.message}
                                        </span>
                                    )}
                                </div>

                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="department">
                                        Department
                                    </label>
                                    <input
                                        type="text"
                                        id="department"
                                        className={tw(
                                            `titext`,
                                            errors.Department
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Department"
                                        {...register('Department')}
                                    />
                                    {errors.Department && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.Department.message}
                                        </span>
                                    )}
                                </div>

                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="regNo">
                                        Registration Number (Mandatory)
                                    </label>
                                    <input
                                        type="text"
                                        id="regNo"
                                        className={tw(
                                            `titext`,
                                            errors.regNo ? 'titext-error' : ''
                                        )}
                                        placeholder="Registration Number"
                                        {...register('regNo')}
                                    />
                                    {errors.regNo && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.regNo.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </>
                    </>
                )}

                {instType == 'Enthusiast' && (
                    <>
                        <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                            <div className="flex w-full flex-1 flex-col space-y-2">
                                <label htmlFor="ayurvedaStudy">
                                    Have you studied Ayurveda?
                                </label>
                                <select
                                    id="ayurvedaStudy"
                                    className={tw(
                                        `titext`,
                                        errors.AyurvedaStudy
                                            ? 'titext-error'
                                            : ''
                                    )}
                                    {...register('AyurvedaStudy')}
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="No" selected>
                                        No
                                    </option>
                                    <option value="Yes">Yes</option>
                                </select>
                                {errors.AyurvedaStudy && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.AyurvedaStudy.message}
                                    </span>
                                )}
                            </div>

                            {getValues('AyurvedaStudy') === 'Yes' && (
                                <div className="flex w-full flex-1 flex-col space-y-2">
                                    <label htmlFor="ayurvedaInstitution">
                                        Institution Name
                                    </label>
                                    <input
                                        type="text"
                                        id="ayurvedaInstitution"
                                        className={tw(
                                            `titext`,
                                            errors.AyurvedaInstitution
                                                ? 'titext-error'
                                                : ''
                                        )}
                                        placeholder="Institution Name"
                                        {...register('AyurvedaInstitution')}
                                    />
                                    {errors.AyurvedaInstitution && (
                                        <span className="text-sm font-medium text-red-500">
                                            {errors.AyurvedaInstitution.message}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                            <div className="flex w-full flex-1 flex-col space-y-2">
                                <label htmlFor="Designation">Profession</label>
                                <input
                                    id="Designation"
                                    type="text"
                                    className={tw(
                                        `titext`,
                                        errors.Designation ? 'titext-error' : ''
                                    )}
                                    {...register('Designation')}
                                    placeholder="Enter your Profession"
                                />
                                {errors.Designation && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Designation.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
                            <div className="flex w-full flex-1 flex-col space-y-2">
                                <label htmlFor="Description">
                                    Reason to Learn Ayurveda
                                </label>
                                <textarea
                                    id="Description"
                                    className={tw(
                                        `titext`,
                                        errors.Description ? 'titext-error' : ''
                                    )}
                                    placeholder="Reason to Learn Ayurveda"
                                    {...register('Description')}
                                />
                                {errors.Description && (
                                    <span className="text-sm font-medium text-red-500">
                                        {errors.Description.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <div className="mt-4 flex w-full flex-1 flex-col space-y-2">
                    <button
                        type="submit"
                        disabled={spinner}
                        className="mx-auto flex w-fit items-center space-x-2 rounded-md border bg-brown p-2 px-4 font-medium capitalize tracking-wide text-white transition-all hover:bg-darkBrown"
                    >
                        <span>{spinner ? 'Registering...' : 'Sign Up'}</span>
                        <ImSpinner2
                            className={`${spinner ? 'block animate-spin' : 'hidden'}`}
                        />
                    </button>
                </div>
            </form>
            <div className="mt-3">
                Already A User? <Link to={'/'}>Login</Link>
            </div>
        </div>
    );
};

export default RegisterForm;
