import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { audioState } from '../../audioState';
import { AudioUrl } from '../../Constants/Config';

function AudioPlayModal({ showModal, handleCloseModal, AudioData }) {
    const [audioData, setAudioData] = useRecoilState(audioState);
    const audioRef = useRef(null);

    function extractIframeSrc(VideoUrl) {
        const match = VideoUrl.match(/src="([^"]+)"/);
        return match ? match[1] : null;
    }

    useEffect(() => {
        if (AudioData?.AudioSecretKey) {
            const isYouTubeLink =
                AudioData.AudioSecretKey.includes('youtube.com') ||
                AudioData.AudioSecretKey.includes('youtube');
            const isZohoWorkdrive =
                AudioData.AudioSecretKey.includes('zohoexternal.com');

            const audioSrc = isYouTubeLink
                ? `https://www.youtube.com/embed/${AudioData.AudioSecretKey.split('v=')[1]}?autoplay=1`
                : isZohoWorkdrive
                  ? AudioData.AudioSecretKey
                  : `${AudioUrl}${AudioData.AudioSecretKey}`;

            // // Check for VideoUrl and extract if available
            // const videoSrc = audioData.VideoUrl ? extractIframeSrc(audioData.VideoUrl) : null;
            // console.log(videoSrc, 'videosrc111');

            // Set audio data and ensure it plays
            setAudioData((prev) => ({
                ...prev,
                audioSrc: audioSrc,
                isPlaying: true,
                isMinimized: false,
                currentTime: prev.currentTime || 0 ,
                videoUrl: url
            }));
        }
    }, [AudioData, setAudioData]);

    const iframeString = `<iframe src="https://workdrive.zohoexternal.com/embed/0u1b341586d8654074702965863484d870d4e?toolbar=false&appearance=light&themecolor=green" scrolling="no" frameborder="0" allowfullscreen=true width="800" height="520" title="Embed code" ></iframe>`;

    function extractUrl(iframeString) {
        const regex = /src=["']([^"']+)["']/;
        const match = iframeString.match(regex);
        return match ? match[1] : null;
    }
    const url = extractUrl(iframeString);

    const handleMinimize = () => {
        // Store the current time before minimizing
        const currentTime = audioRef.current.currentTime;
        setAudioData((prev) => ({
            ...prev,
            isMinimized: true,
            currentTime: currentTime ,
            videoUrl:url,
            isPlaying:true
        }));
    };

    const handleClose = () => {
        setAudioData((prev) => ({
            ...prev,
            isPlaying: false,
            isMinimized: false
        }));

        handleCloseModal();
        window.location.reload();
    };

    return (
        <>
            {!audioData.isMinimized && (
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Body
                        style={{ position: 'relative', padding: '20px' }}
                    >
                        {AudioData?.VideoUrl ? ( // Check VideoUrl first
                            <iframe
                            ref={audioRef}

                                src={url} // Use VideoUrl here
                                autoPlay
                                style={{ width: '100%', height: '360px' }}
                            
                            />
                        ) : audioData?.audioSrc ? ( // Then check audioSrc
                            <audio
                                ref={audioRef}
                                src={audioData.audioSrc}
                                autoPlay={audioData.isPlaying}
                                controls
                                onEnded={() =>
                                    setAudioData((prev) => ({
                                        ...prev,
                                        isPlaying: false
                                    }))
                                }
                                style={{ width: '100%', height: '60px' }}
                            />
                        ) : null}

                        {/* Added fallback for when neither audio nor video is available */}

                        <button
                            onClick={handleMinimize}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px'
                            }}
                        >
                            <i className="fas fa-minus" />
                        </button>
                        <button
                            onClick={handleClose}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '50px'
                            }}
                        >
                            <i className="fas fa-times" />
                        </button>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default AudioPlayModal;
